import React, { useState, useEffect } from 'react';
import "./FeedbackForm.css"
import StudentInfo from "../components/student-info";

import { feedbackApiService } from '../services/FeedbackApiService';
import Spinner from '../components/spinner';

function FeedbackForm() {
  const feedbackApiServiceObj = new feedbackApiService();
  const [formSubmitionStatus, setFormSubmitionStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cdacCdacCentreIds, setCdacCdacCentreIds] = useState()
  const [cdacCdacCourseIds, setCdacCdacCourseIds] = useState()

  const [studentEmail, setStudentEmail] = useState("");
  const [student, setStudent] = useState(null);

  const [formData, setFormData] = useState({
    "RegistrationNo": "",
    "Rating": "5",
    "IsAdmissionTaken": "Y",
    "RecommendStatus": "Y",
    "CCATRankAB": "",
    "CCATRankABC": "0",
    "Reason": "",
    "CdacCourseId": "0",
    "CdacCentreId": "0",
    "Comments": "",
    "Reference1": "",
    "MobileNo1": "",
    "Reference2": "",
    "MobileNo2": "",
    "LastModifiedBy":""
  });


  useEffect(() => {
    const fetchCdacCentreIds = async () => {
      setLoading(true); // Start loading
      try {
        const result = await feedbackApiServiceObj.getCdacCentres();
        if (result.error === null && result.status === "success") {
          let optionItems1 = result.data.map((item) =>
            <option key={item.CentreCode} value={item.CentreCode}>{item.CentreNameCity}</option>
          );
          setCdacCdacCentreIds(optionItems1);
        }

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchCdacCourseIds = async () => {
      setLoading(true); // Start loading
      try {
        const result = await feedbackApiServiceObj.getCdacCourses();
        if (result.error === null && result.status === "success") {
          let optionItems2 = result.data.map((item) =>
            <option key={item.CourseCode} value={item.CourseCode}>{item.CourseName}</option>
          );
          setCdacCdacCourseIds(optionItems2);
        }

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCdacCentreIds();
    fetchCdacCourseIds();

  }, []);

  
  const saveFeedback = async (feedback) => {
    //setLoading(true); // Start loading
    try {
      const result = await feedbackApiServiceObj.saveFeedback(feedback);
      if (result.error === null && result.status === "success") {
        setFormSubmitionStatus(true)
      }

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    debugger;
    event.preventDefault();
    if (!event.target.checkValidity()) {
      event.stopPropagation();
      event.target.classList.add('was-validated');
      
      return;
    }
    saveFeedback(formData);
    event.target.classList.add('was-validated');
  };

  const handleChange = (e) => {
    //debugger
    const { name, value } = e.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value
  }));
  formData["RegistrationNo"]= student.RegistrationNo
  };

  useEffect(() => {
    console.log("Form Data Changed:", formData);
  }, [formData]);

  const searchStudent = async () => {
    try {
      //debugger;
      setLoading(true);
      const result = await feedbackApiServiceObj.getStudent(studentEmail);
      if (result.error === null && result.status === "success") {
        setStudent(result.data[0]);
           }
          } 
           catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }
  if (loading) return <Spinner></Spinner>;

  if (error) return <div>Error: {error.message}</div>;

  if (formSubmitionStatus) return <div className="alert alert-success" role="alert">
  Thank you for taking the time to provide your feedback. 
</div>

  if (student == null)
    return (
      <>
        <div className="overflow-auto">
          <fieldset className="border rounded border-secondary">
            <legend>Enter Email-Id</legend>
            <div className="input-group mb-4">
              <span className="input-group-text">@</span>
              <input type="email" className="form-control" placeholder="Email-Id" aria-label="Email-Id"
                onChange={(event) => { setStudentEmail(event.target.value) }} />
              {/* <span className="input-group-text">Roll No</span>
            <input type="number" className="form-control" placeholder="Roll No" aria-label="Roll No" /> */}
              <button className="btn-primary" onClick={!loading ? searchStudent : null}>
                Search
              </button>
            </div>
          </fieldset>
        </div>
      </>
    )
  if (student != null) {
    return (
      <>
        <br />
        <div className="overflow-auto">
          <StudentInfo student={student}></StudentInfo>
          <div className="container align-middle">
            <form className="row g-3 needs-validation" method="post" onSubmit={handleSubmit} noValidate>
              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating">
                    <input type="number" className="form-control" name="CCATRankAB" id="CCATRankAB" placeholder="C-CAT Rank (A+B)" min="1" max="15000" maxLength="5" required value={formData.CCATRankAB} onChange={handleChange} />
                    <label htmlFor="CCATRankAB">C-CAT Rank (A+B):</label>
                    <div className="invalid-feedback">
                      Enter A+B Rank between 1 - 15K
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <input type="number" className="form-control" name="CCATRankABC" id="CCATRankABC" placeholder="C-CAT Rank ABC" min="0" max="15000" maxLength="5" value={formData.CCATRankABC} onChange={handleChange} />
                    <label htmlFor="CCATRankABC">C-CAT Rank (A+B+C):</label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <span>PG-CDAC Course Admission taken (Yes/No)?</span>
                <input type="radio" className="form-check-input" name="IsAdmissionTaken" id="Yes1" autoComplete="off" value="Y" checked={formData.IsAdmissionTaken === "Y"} onChange={handleChange} required />
                <label className="form-check-label" htmlFor="Yes1">Yes</label>
                <input type="radio" className="form-check-input" name="IsAdmissionTaken" id="No1" autoComplete="off" value="N" checked={formData.IsAdmissionTaken === "N"} onChange={handleChange} />
                <label className="form-check-label" htmlFor="No1">No</label>

              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <textarea className="form-control" placeholder="Leave a reason here" name="Reason" id="Reason" value={formData.Reason} onChange={handleChange}></textarea>
                  <label htmlFor="Reason">If No for PG-CDAC Course then mention reason?</label>
                </div>
              </div>
              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating">
                    <select className="form-control form-select" id="CdacCourseId" name="CdacCourseId" aria-label="Final PG-CdacCourseId" required value={formData.CdacCourseId} onChange={handleChange}>
                      <option value="0">--Select--</option>
                      {cdacCdacCourseIds}
                    </select>
                    <label htmlFor="CdacCourseId">Select Course</label>
                    <div className="invalid-feedback">
                      Select Course
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <select className="form-control form-select" name="CdacCentreId" id="CdacCentreId" aria-label="Select CdacCentreId" required value={formData.CdacCentreId} onChange={handleChange}>
                      <option value="0">--Select--</option>
                      {cdacCdacCentreIds}
                    </select>
                    <label htmlFor="CdacCentreId">Select Centre</label>
                    <div className="invalid-feedback">
                      Select Centre
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-floating">
                    <textarea className="form-control" placeholder="Feedback about PreCAT CdacCourseId" id="Comments" name="Comments" required value={formData.Comments} onChange={handleChange}></textarea>
                    <label htmlFor="Comments">Feedback about PreCAT Course(Comments):</label>
                    <div className="invalid-feedback">
                      Enter Comments
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  Rate PreCAT CdacCourseId out of 5:
                  <div className="rating">
                    <input type="radio" className="form-input form-check-input" name="Rating" value="5" id="Rating5" checked={formData.Rating === "5"} onChange={handleChange} required />
                    <label htmlFor="Rating5">☆</label>
                    <input type="radio" className="form-input form-check-input" name="Rating" value="4" id="Rating4" checked={formData.Rating === "4"} onChange={handleChange} />
                    <label htmlFor="Rating4">☆</label>
                    <input type="radio" className="form-input form-check-input" name="Rating" value="3" id="Rating3" checked={formData.Rating === "3"} onChange={handleChange} />
                    <label htmlFor="Rating3">☆</label>
                    <input type="radio" className="form-input form-check-input" name="Rating" value="2" id="Rating2" checked={formData.Rating === "2"} onChange={handleChange} />
                    <label htmlFor="Rating2">☆</label>
                    <input type="radio" className="form-input form-check-input" name="Rating" value="1" id="Rating1" checked={formData.Rating === "1"} onChange={handleChange} />
                    <label htmlFor="Rating1">☆</label>
                  </div>
                  <div className="mb-3">
                    <span>Will you recommend Sunbeam PreCAT to friends and relatives Y/N?</span>
                    <input type="radio" className="form-check-input" name="RecommendStatus" id="Yes2" autoComplete="off" value="Y" checked={formData.RecommendStatus === "Y"} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="Yes2">Yes</label>
                    <input type="radio" className="form-check-input" name="RecommendStatus" id="No2" autoComplete="off" value="N" checked={formData.RecommendStatus === "N"} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="No2">No</label>
                  </div>
                  <fieldset>
                    <legend>Do you have 1 or 2 references to be mentioned now:</legend>
                    <div className="input-group mb-4">
                      <span className="input-group-text">Reference 1</span>
                      <input type="text" className="form-control" placeholder="Name" aria-label="Name" id="Reference1" name="Reference1" value={formData.Reference1} onChange={handleChange} />
                      <input type="number" className="form-control" placeholder="Mobile No" aria-label="Mobile No" id="MobileNo1" name="MobileNo1" value={formData.MobileNo1} onChange={handleChange}  />
                    </div>
                    <div className="input-group mb-4">
                      <span className="input-group-text">Reference 2</span>
                      <input type="text" className="form-control" placeholder="Name" aria-label="Name" id="Reference2" name="Reference2" value={formData.Reference2} onChange={handleChange} />
                      <input type="number" className="form-control" placeholder="Mobile No" aria-label="Mobile No" id="MobileNo2" name="MobileNo2" value={formData.MobileNo2} onChange={handleChange} />
                    </div>
                  </fieldset>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col text-center">
                      <button type="submit" className="btn btn-primary btn-lg" style={{ width: "20%" }}>Submit Feedback</button>
                    </div>
                  </div>
                </div>

              </div></form>
            <br /><br /><br /><br /><br /><br />

          </div>

        </div>

      </>
    );

  }
}

export default FeedbackForm;
